.profile .goBack {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0px;
}
.profile {
    width: 76%;
}
@media (max-width: 940px) {
    .profile {
        width: 100%;
    }  
}
.profile .profile_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.profile .profile_header .ant-avatar {
    margin-bottom: 10px;
}
.profile .profile_header span {
  font-size: 30px;
}