.mobile {
    display: none;
}
.filter{
    display:none;
}
.web-filter {
width: 20%;
}
@media (max-width: 940px)  {
    .main {
        display: block;
    }
    .web-filter {
        display: none;
    }      
    .mobile {
        display: block;
    }
    .filter{
      
        position: relative;
        margin: 10px 0;
        width: 40px;
    height: 40px;
    background-color: #fb5c3d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
.footer a {
    color: #fff;
    margin: 10px;
}
.fact-list {
    width: 76% !important;
}

@media (max-width: 940px) {
    .fact-list {
        width: 100% !important;
    }

}