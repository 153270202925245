.comment {
    margin: 15px 0;
    background-color: #44403c;
    padding: 15px;
    border-radius: 15px;
}
.comment  p{
    margin-top: 5px;
    padding: 10px;
}
.comment  .time{
   text-align: end;
}
.comment .comment_header {
    margin-bottom: 10px;
}