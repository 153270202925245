.privacy-page {
    padding: 20px;
}
.privacy-page h1{
    font-family: 'Arial';
    text-align: center;
    margin-bottom: 20px;
}
.privacy-page h2{
    font-family: 'Arial';
    margin: 20px 0;
}
.privacy-page h3{
    font-family: 'Arial';
    margin: 20px 0;
}
.privacy-page p {
    margin: 10px 0;
}
.privacy-page ul li {

        list-style-type: circle;
      
}