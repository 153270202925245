.header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-group .btn:first-child{
    margin-right: 30px;
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .logo img {
    width: 68px;
    height: 68px;
  }
.mobileIcon{
  display: none;
}
  @media (max-width: 940px)  {
    .logo img {
      width: 30px;
      height: 30px;
    }
    .logo h1 {
    font-size: 20px;
    }
.mobileIcon{
  display: block;
  font-size: large;
}
    .mobileNone{
      display: none;
    }
  }
 .link , h1{
    text-decoration: none;
    color: white;
  }

  .mobileMenu{
    display: flex;
    justify-content: space-evenly;
    height:100px;
    flex-direction: column;
  }

  