.tags-input-container .tags {
    max-width: 300px;
    max-height: 50px;
}
.tags-input-container .tags .tag-item{
background-color: rgb(212, 210, 210);
margin: 5px;
display: inline-block;
color: #000;
padding: 5px;
}
.tags-input-container .tags .tag-item .close{
color: red;
cursor: pointer;
margin-right: 0;
}