.Story-modal .ant-modal-content, .Story-modal .ant-modal-content .ant-modal-title{
    background-color: #292524;
    color: white;
}
.ant-modal-close-x{
background-color: white;
border-radius: 50%;
padding: 1px;
color: #292524;
}
.Story-modal .ant-modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.Story-modal .cancel {
    background-color: white;
    color: #292524;
    border: none;
}
.Story-modal .cancel:hover {
    color: #292524;
}
 .Ai-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
flex-direction: column;
}