.custom_drawer {
    background-color: #44403c !important;
}
.custom_drawer .ant-drawer-header .ant-drawer-title{
    color: white;
}
.custom_drawer .ant-drawer-header  .ant-drawer-close{
    position: absolute;
    right: 0;
    color: white;
}