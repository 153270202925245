.post-details {
    display: flex;
    flex-direction: column;
    max-width: 76%;
}
@media (max-width: 940px) {
    .post-details {
        max-width: 100%;
    }
}
.post-details a{
color: #fff;
text-decoration: none;
margin-bottom: 20px;
}

.post-details .comment_form{
    padding: 15px;
    border-radius: 20px;
    background-color: #44403c;
    margin-bottom: 20px;
}

.post-details .comment_form .submit-container {
    display: flex;
    justify-content: center;
}
.post-details .comment_form .ant-btn {
    width: 300px;
    background-color: #3b82f6;
}